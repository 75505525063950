body {
  /* height: 2000px; */
  overflow-x: hidden;
}

.App {
  text-align: center;
  /* width: 200vh; */
  height: fit-content;
  overflow: hidden;
}

.Title {
  font-size: 5rem;
  letter-spacing: 1.2rem;
  margin: 0;
  font-weight: 800;
  color: #001849;
}

.SubTitle {
  font-size: 1.5rem;
  letter-spacing: 0.8rem;
  margin: 0;
}

.snow-container {
  position: absolute;
  width: 100%;
  height: 150vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.snow-flake {
  color: dano-coral;
  opacity: 0;
  animation: fall 10s linear infinite;
}
@keyframes fall {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    transform: translate(20px, 100vh);
    opacity: 0;
  }
}

.keyword {
  width: fit-content;
}

.keywords {
  gap: 20px;
  display: flex;
  justify-content: space-around;
  margin-left: 20%;
  margin-right: 20%;
  flex-wrap: wrap;
}

.timeContainer {
  background-color: #ececec;
  border-radius: 25px;
  width: 60%;
  margin: 0 auto;
  height: fit-content;
  padding: 30px 20px;
}

.timeText {
  font-size: 2rem;
  letter-spacing: 0rem;
  font-weight: 700;
  margin: 0;
}

.subText {
  font-size: 0.8rem;
  letter-spacing: 0rem;
  font-weight: 400;
  margin: 0;
}

.visionText {
  height: fit-content;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  margin: 0;
}

.trackContainer {
  /* background-color: #ececec; */
  border-radius: 25px;
  width: 60%;
  margin: 0 auto;
  height: fit-content;
  /* padding: 30px 0px; */
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.startupContainer {
  background-color: #f37b7b;
  /* height: 20px; */
  border-radius: 25px;
  width: 45%;
  /* margin: 0 auto; */
  height: fit-content;
  padding: 10px 20px;
  /* display: flex; */
  text-align: left;
}

.researchContainer {
  background-color: #7b97f3;
  border-radius: 25px;
  width: 45%;
  /* margin: 0 auto; */
  height: fit-content;
  padding: 10px 20px;
  /* display: flex; */
  text-align: left;
}

.trackTitle {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin: 0;
}

.LastText {
  font-size: 1.5rem;
  /* letter-spacing: 0.1rem; */
  font-weight: 500;
  margin: 0;
}

.LastBtn {
  background-color: #001849;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  height: fit-content;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  color: white;
}
